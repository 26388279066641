import type { ProductSurveyAnswers } from '@tn/shared';
import EVENTS from '~/constants/events';
import { useMemberReviews } from '~/composables/useMemberReviews';

const MEMBER_REVIEW_START = 'members-review-start';
const MEMBER_REVIEW_QUESTION = 'members-review-question';
const MEMBER_WALLET = 'wallet';

const getLastCompletedStep = (review: ProductSurveyAnswers) => {
  let maxOrder = -1;
  let stepIdWithMaxOrder: string | null = null;

  const answers = Object.keys(review.answers);

  if (!answers.length) {
    const surveySteps = review.survey.steps;

    const firstStep = Object.values(surveySteps).find(
      (surveyStep) => surveyStep.order === 0
    );

    return { stepId: firstStep?.stepId, order: firstStep?.order };
  }

  for (const stepId of Object.keys(review.survey.steps)) {
    const step = review.survey.steps[stepId];
    const questionIds = Object.keys(step.questions);

    const isStepAnswered = questionIds.every((id) => answers.includes(id));

    if (isStepAnswered && step.order > maxOrder) {
      maxOrder = step.order;
      stepIdWithMaxOrder = stepId;
    }
  }

  return { stepId: stepIdWithMaxOrder, order: maxOrder };
};

const getFirstUncompletedStep = (
  currentStepId: string | null | undefined,
  review: ProductSurveyAnswers
) => {
  if (!currentStepId) {
    return { stepId: null, order: null };
  }

  const surveySteps = review.survey.steps;
  const answers = Object.keys(review.answers);

  if (!answers.length) {
    const firstStep = Object.values(surveySteps).find(
      (surveyStep) => surveyStep.order === 0
    );
    return { stepId: firstStep?.stepId, order: firstStep?.order };
  }

  const currentOrder = surveySteps[currentStepId]?.order;

  const sortedSteps = Object.values(surveySteps).sort(
    (a, b) => a.order - b.order
  );

  const nextStep = sortedSteps.find((step) => {
    const questionIds = Object.keys(step.questions);
    return (
      step.order > currentOrder &&
      questionIds.some((id) => !answers.includes(id))
    );
  });

  return { stepId: nextStep?.stepId, order: nextStep?.order };
};

const getNextStep = (stepId: string, review: ProductSurveyAnswers) => {
  const currentStep = review.survey.steps[stepId];

  if (!currentStep) {
    return null;
  }

  return {
    stepId: currentStep.stepId,
    order: currentStep.order,
  };
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!import.meta.client) {
    return;
  }

  const { $trackEvent } = useNuxtApp();

  const reviewId = to.params.reviewId as string;
  const nextQuestionId = to.params.id as string;

  const { currentReview, currentReviewedProduct, isReportReview } =
    useMemberReviews(reviewId);

  const fromName = from.name;
  if (!currentReview.value) {
    if (fromName) {
      return navigateTo({ name: fromName });
    }

    return;
  }

  const totalAnswers = Object.keys(currentReview.value.answers).length;
  const completed = currentReview.value.completedAtTimeMs;
  const lastCompletedStep = getLastCompletedStep(currentReview.value);
  const lastUncompletedStep = getFirstUncompletedStep(
    lastCompletedStep?.stepId,
    currentReview.value
  );

  if (to.name === MEMBER_REVIEW_START && completed) {
    return navigateTo({
      name: MEMBER_WALLET,
    });
  }

  if (
    to.name === MEMBER_REVIEW_START &&
    fromName !== MEMBER_REVIEW_QUESTION &&
    !completed &&
    totalAnswers > 0
  ) {
    if (!isReportReview.value) {
      $trackEvent(EVENTS.SAMPLE_REVIEW_STARTED, {
        page_landed: 'review_step_sampler_onboarding',
        brand_name: currentReviewedProduct.value?.brandName,
        brand_id: currentReviewedProduct.value?.brandId,
        product_name: currentReviewedProduct.value?.title,
        product_id: currentReview.value.productId,
      });
    }

    return navigateTo({
      name: MEMBER_REVIEW_QUESTION,
      params: { reviewId, id: lastUncompletedStep.stepId },
    });
  }

  const nextStep = getNextStep(nextQuestionId, currentReview.value);

  if (
    nextStep &&
    lastUncompletedStep &&
    typeof lastUncompletedStep.order === 'number' &&
    nextStep.order > lastUncompletedStep.order
  ) {
    return navigateTo({
      name: MEMBER_REVIEW_QUESTION,
      params: { reviewId, id: lastUncompletedStep.stepId },
    });
  }

  if (
    fromName === MEMBER_WALLET &&
    to.name === MEMBER_REVIEW_QUESTION &&
    completed
  ) {
    return navigateTo({
      name: MEMBER_WALLET,
    });
  }
});
